import React from "react"
import { Link } from "gatsby";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Section from "../../components/template-partials/global-components/section"
import ShareButtons from "../../components/template-partials/global-components/share-buttons"
import InteriorMenu from "../../components/template-partials/global-components/interior-menu"
import InteriorPageHeader from "../../components/template-partials/global-components/interior-page-header"
import InteriorPageFooter from "../../components/template-partials/global-components/interior-page-footer"
import SupportiveCareSubmenu from "../../components/template-partials/supportive-care/supportive-care-submenu"
import SupportiveCareFooterLinks from "../../components/template-partials/supportive-care/supportive-care-footer-links"
import headingBg from "../../images/4.0-headerbg.jpg"
import headingRound from "../../images/supportive-care-nav-image.jpg"
import AlertInfoCard from "../../components/template-partials/global-components/alert-infocard"

import {ExploreCard} from "../../components/template-partials/global-components/footer-cards"

import painHealthTeam from "../../images/4.8.3_pain_healthteam.jpg"
import painMeds from "../../images/4.8.2_pain_meds.jpg"
import painHome from "../../images/4.8.4_pain_home.jpg"
import painSigns from "../../images/4.8.1_pain_signs.jpg"

const PainPage = () => {
  return (
    <Layout>
      <SEO title="Neuroblastoma Supportive Care | Pain" description="During their neuroblastoma treatment, it is likely that your child will experience some amount of pain. It is important to determine the cause and type of pain in order to best manage it. Read aboutpotential signs, symptoms, causes, and treatments." />

      <InteriorPageHeader backgroundImage={headingBg} roundImage={headingRound} alt="Pain">
        <h1 className="section__heading section__heading--green h1">Pain</h1>
      </InteriorPageHeader>

      {/* style={{backgroundColor: SassVars.viridian}} */}

      <Section className="interior-page">
        <div className="row row--inner">
          <div className="columns small-12 large-4">
            <InteriorMenu title={`Supportive Care`}>
              <SupportiveCareSubmenu/>
            </InteriorMenu>
          </div>
          <div className="columns">
            <div className="row">
              <div className="columns">
                <ShareButtons/>
              </div>
            </div>
            <div className="row">
              <div className="columns">
                <p>It is likely your child will experience pain during their neuroblastoma treatment. As a parent or caregiver, it is understandable that you want to spare your child from pain. Determining the cause and type of pain will help your child’s healthcare team decide how best to manage it and keep your child comfortable.</p>
                <h2 className="tc-barney h2 jost-med">Potential causes of pain</h2>
                <p>Pain may be caused by many factors during neuroblastoma treatment, such as:</p>
                <ul className="ul--dotted">
                  <li>The location of the tumor</li>
                  <li>Effects from certain <Link rel="glossary" to="/resources/glossary/#chemotherapy">chemotherapy</Link> medicines</li>
                  <li>Radiation</li>
                  <li>Surgery</li>
                  <li><Link rel="glossary" to="/resources/glossary/#antibody-therapy">Antibody therapy</Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row row--inner align-middle align-justify">
          <div className="columns large-3 small-12 text-center">
            <img src={painSigns} alt="" style={{maxWidth: `111px`}}/>
          </div>
          <div className="columns mt-2">
            <h3 className="tc-viridian jost-med h3">Signs and symptoms of pain </h3>
            <p>Signs and symptoms of pain can vary depending on the cause of pain. You know your child best, and your healthcare team will rely on you to tell them if you think your child is in pain.</p>
          </div>
        </div>

        <div className="row row--inner lg_mt-4 mt-4 align-middle align-justify">
          <div className="columns large-3 small-12 text-center">
            <img src={painHealthTeam} alt="" style={{maxWidth: `85px`}}/>
          </div>
          <div className="columns mt-2">
            <h3 className="tc-viridian jost-med h3">Treating pain </h3>
            <p>The healthcare team will work to figure out what is causing the pain and make a plan to decrease the pain. Pain can be treated with medications as well as comfort (nonmedication) techniques. A combination of these 2 approaches is often the most helpful.</p>
          </div>
        </div>

        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns large-3 small-12 text-center">
            <img src={painMeds} alt="" style={{maxWidth: `111px`}}/>
          </div>
          <div className="columns mt-2">
            <h3 className="tc-viridian jost-med h3">Medications </h3>
            <p>Your child’s healthcare team will choose the appropriate medication for your child. This will depend on the type and severity of pain your child is having. Different types of pain medications can be given in the following ways:</p>
            <ul className="ul--dotted">
              <li>Topically (cream or patch)</li>
              <li>By mouth (pill or liquid)</li>
              <li><Link rel="glossary" to="/resources/glossary/#intravenously">IV</Link> or <Link rel="glossary" to="/resources/glossary/#central-line-or-cl">central line</Link></li>
              <li>Catheter in their back (epidural)</li>
            </ul>
          </div>
        </div>

        <div className="row row--inner lg_mt-4 mt-4">
          <div className="columns large-3 small-12 text-center">
            <img src={painHome} alt="" style={{maxWidth: `111px`}}/>
          </div>
          <div className="columns mt-2">
            <h3 className="tc-viridian jost-med h3">Managing pain at home </h3>
            <p>Approaches to manage your child’s pain at home and keep them comfortable without the use of medication include:</p>
            <ul className="ul--dotted">
              <li>Cold/heat</li>
              <li>Distraction</li>
              <li>Massage</li>
              <li>Relaxation (using techniques such as deep breathing, meditation, or soothing music)</li>
              <li>Exercise</li>
            </ul>
            <p>While your child’s pain will be managed in the hospital during treatments, your child’s healthcare team may prescribe medications for them to take when they are at home under your care. </p>
          </div>
        </div>

        <AlertInfoCard className="lg_mt-2 lg_mb-2" text="Please call your child's healthcare team if your child has new or increasing pain, or pain that doesn't improve with pain medication."/>

        <SupportiveCareFooterLinks/>
      </Section>

      <InteriorPageFooter>

      <ExploreCard title="Looking for additional resources?" lead="Find helpful resources for you and your child to use during your neuroblastoma journey." url="/resources/downloadable-resources/"/>


      </InteriorPageFooter>

    </Layout>
  )
}

export default PainPage
